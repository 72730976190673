import { Footer, NavBar }  from "../../components";

export const Services = () => {
  return (
      <div>
          <NavBar link="Services"/>

          <Footer />
      </div>
  );
};